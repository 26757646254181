import React, {Component} from "react";
import {observer, inject} from "mobx-react";
import md5 from "js-md5";
import {message, Tooltip} from "antd";
import axios from "axios";
import {solveHtml} from "../../utils/converter";
import {LAYOUT_ID, ENTER_DELAY, LEAVE_DELAY, PING_GU_API_PREFIX} from "../../utils/constant";
import SvgIcon from "../../icon";
import ThreadDialog from "../pinggu/ThreadDialog";
import "./Issue.css";
import qs from "qs";
import threadDataStore from "../../store/pinggu/threadDataStore";

@inject("content")
@inject("navbar")
@inject("imageHosting")
@inject("dialog")
@observer
class Issue extends Component {
  constructor(props) {
    super(props);
    this.html = "";
    console.log(this.props);
  }

  handlePingGuSubmit = () => {
    if (!window) {
      message.error("window不可用");
      return;
    }

    const query = this.parseQuery(window.location.search.slice(1));

    const layout = document.getElementById(LAYOUT_ID); // 保护现场
    const html = layout.innerHTML;
    this.html = solveHtml();
    const content = this.props.content.getContent();

    let data = {
      fid: query.fid * 1,
      subject: threadDataStore.subject,
      message: this.html,
      markdown: content,
      hiddenreplies: threadDataStore.hiddenreplies, // 回帖仅作者可见
      ordertype: threadDataStore.ordertype, // 回帖倒序排列
      usesig: threadDataStore.usesig, // 使用个人签名
      allownoticeauthor: threadDataStore.allownoticeauthor, // 接收回复通知
    };

    // 如果 select 不为空，加入 typeid
    if (threadDataStore.select !== null && threadDataStore.select !== undefined) {
      data = {...data, typeid: threadDataStore.select};
    }

    if (threadDataStore.postTime && threadDataStore.allowSetPostTime === "1") {
      data = {...data, postTime: threadDataStore.postTime};
    }

    // 根据不同的 action 设置不同的 URL
    let url = `${PING_GU_API_PREFIX}/markdown.php?action=newthread&op=post`;
    if (query.action === "edit") {
      url = `${PING_GU_API_PREFIX}/markdown.php?action=edit&page=1&op=post`;
      data = {...data, tid: query.tid * 1, pid: query.pid * 1};
    } else if (query.action === "reply") {
      url = `${PING_GU_API_PREFIX}/markdown.php?action=reply&page=1&op=post`;
      data = {...data, tid: query.tid * 1};
    }

    // 发起请求，显示 loading
    message.loading("发布中...", 0);
    axios
      .post(url, qs.stringify(data), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => {
        message.destroy(); // 移除 loading 提示
        if (response.status === 200) {
          if (response.data.code === "success") {
            message.success("发布成功");
            window.location.href = response.data.url; // 跳转到发布后的页面
          } else {
            message.error(response.data.msg || "发布失败");
          }
        } else if (response.status === 500 || response.status === 504) {
          message.error("发布错误或帖子内容过长");
        } else {
          message.error("发布失败，请稍后再试");
        }
      })
      .catch((error) => {
        message.destroy(); // 移除 loading 提示
        if (error.response && (error.response.status === 500 || error.response.status === 504)) {
          message.error("发布错误或帖子内容过长");
        } else {
          message.error("发布失败，请稍后再试");
        }
      });
  };

  handleConfirm = (_data) => {
    console.log("父组件接收到的数据：", _data);
    this.handlePingGuSubmit();
  };

  pingGuIssue = () => {
    // 论坛发表
    const query = this.parseQuery(window.location.search.slice(1));
    if (query.action === "reply") {
      this.handlePingGuSubmit();
      return;
    }
    threadDataStore.setIsDialogVisible(true);
  };

  issue = () => {
    if (!window) {
      message.error("window不可用");
      return;
    }
    const currentHostname = window.location.hostname;
    if (currentHostname === "markdown.pinggu.org" || currentHostname === "newmd.pinggu.org") {
      this.pingGuIssue();
      return;
    }
    const layout = document.getElementById(LAYOUT_ID); // 保护现场
    const html = layout.innerHTML;
    this.html = solveHtml();
    console.log("页面内容是", this.html);
    const content = this.props.content.getContent();
    const query = this.parseQuery(window.location.search.slice(1));
    var timestamp = new Date().valueOf();
    if (!query.callbackurl) {
      message.error("回调地址不能为空");
      return;
    }
    if (!query.sourceUrl) {
      message.error("sourceUrl不能为空");
      return;
    }
    const callbackUrl = decodeURIComponent(query.callbackurl);
    const salt = "oH4!0I%26a5@+kmC";
    const sign = md5(salt + callbackUrl + timestamp);
    const sourceUrl = decodeURIComponent(query.sourceUrl);
    const data = {
      html: this.html,
      content: content,
      extend: query.extend,
      t: timestamp,
      sign: sign,
    };
    message.loading("上传中..", 0);
    axios.post(callbackUrl, qs.stringify(data)).then((res) => {
      message.destroy();
      if (res.status !== 200) {
        message.error("发布失败,请稍后再试");
      } else {
        if (res.data.code === 0) {
          window.location.href = sourceUrl;
          message.success(res.data.msg || "发布成功");
        } else {
          message.error(res.data.msg || "发布失败");
        }
      }
      layout.innerHTML = html; // 恢复现场
    });
  };

  parseQuery = (query) => {
    const obj = {};
    if (!query) {
      return obj;
    }
    const arr = query.split("&");
    for (let i = 0; i < arr.length; i++) {
      const kv = arr[i].split("=");
      if (kv.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        obj[kv[0]] = kv[1];
      } else if (kv.length > 0) {
        obj[kv[0]] = "";
      }
    }
    return obj;
  };

  render() {
    return (
      <>
        <Tooltip placement="left" mouseEnterDelay={ENTER_DELAY} mouseLeaveDelay={LEAVE_DELAY} title="发布">
          <a id="nice-sidebar-issue" className="nice-btn-issue" onClick={this.issue}>
            <SvgIcon name="issue" className="nice-btn-issue-icon"/>
          </a>
        </Tooltip>

        <ThreadDialog onConfirm={this.handleConfirm}/>
      </>
    );
  }
}

export default Issue;
