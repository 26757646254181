import React from "react";
import "./index.css";

export default ({fill = "rgba(0,0,0,0.65)", style = {}, className = "icon", viewBox = "0 0 1024 1024"}) => (
  <svg
    style={style}
    viewBox={viewBox}
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M21.452433 21.452433m244.557737 0l491.975798 0q244.557737 0 244.557737 244.557737l0 492.404847q0 244.557737-244.557737 244.557737l-491.975798 0q-244.557737 0-244.557737-244.557737l0-492.404847q0-244.557737 244.557737-244.557737Z"
      fill="#FBC239"
      p-id="1145"
    />
    <path
      d="M759.416131 42.904866a223.105304 223.105304 0 0 1 221.675141 223.105304v493.405961a223.105304 223.105304 0 0 1-221.675141 221.675141H266.01017A223.105304 223.105304 0 0 1 42.904866 759.416131V266.01017A223.105304 223.105304 0 0 1 266.01017 42.904866h493.405961m0-42.904866H266.01017A266.01017 266.01017 0 0 0 0 266.01017v493.405961a264.580008 264.580008 0 0 0 266.01017 264.580008h493.405961a264.580008 264.580008 0 0 0 264.580008-264.580008V266.01017A264.580008 264.580008 0 0 0 759.416131 0z"
      fill="#213233"
      p-id="1146"
    />
    <path
      d="M674.278575 84.608396l125.396622 125.396622-83.950522 83.950522-125.38232-125.410924z"
      fill="#FBC239"
      p-id="1147"
    />
    <path
      d="M574.181522 186.149913l125.396622 125.396622-284.673787 284.673787-125.396622-125.396623z"
      fill="#36B0D6"
      p-id="1148"
    />
    <path d="M283.172117 489.115474l112.982814 112.982814-127.284437 21.452433z" fill="#FFFFFF" p-id="1149"></path>
    <path
      d="M583.50618 170.189302l77.228759-77.228759 11.441297-8.580973 25.74292 27.173082a14.301622 14.301622 0 0 0-14.301622 4.290487l-65.787462 65.787461-22.882595 22.882595-278.88163 278.88163-7.150811 5.720649v28.603244l-5.720649 60.066813-32.89373 2.860324 8.580973-98.681192c0-2.860324 1.430162-4.290487 1.430162-5.720649l2.860325-4.290486h1.430162l300.334063-301.764226z"
      fill="#FFFFFF" opacity=".25" p-id="1150"></path>
    <path
      d="M805.181321 177.340113l-97.25103-98.681192a48.625515 48.625515 0 0 0-68.647786 0L261.719683 457.651906h-1.430162l-2.860324 5.720648c0 1.430162-1.430162 2.860324-1.430162 4.290487l-14.301622 157.317842a15.731784 15.731784 0 0 0 15.731784 17.161947h2.860324l157.317843-14.301622h4.290486l4.290487-2.860325h1.430162l300.334063-300.334063 77.228759-78.658921a47.195353 47.195353 0 0 0 0-67.217623z m-231.686277 12.87146l120.133625 121.563788-277.451467 277.451467-121.563788-120.133625zM276.021306 609.249099l10.011135-104.401841 94.390705 94.390706z m507.707582-387.573957l-67.217623 67.217623-120.133626-121.563787 65.787462-65.787461a17.161946 17.161946 0 0 1 22.882595 0l98.681192 98.681192a15.731784 15.731784 0 0 1 0 21.452433z"
      fill="#213233" p-id="1151"></path>
    <path
      d="M440.489959 460.51223a15.731784 15.731784 0 0 0 11.441298-4.290487l127.284436-127.284436a15.731784 15.731784 0 1 0-22.882595-22.882595L429.048661 433.339148a17.161946 17.161946 0 0 0 0 22.882595 15.731784 15.731784 0 0 0 11.441298 4.290487z"
      fill="#213233" p-id="1152"></path>
    <path
      d="M340.378605 463.372554h4.290486l28.603244-28.603244a5.720649 5.720649 0 0 0-7.150811-7.150811l-28.603244 28.603244c-2.860324 2.860324-2.860324 5.720649 0 7.150811s1.430162 0 2.860325 0zM383.283471 421.89785h2.860324l4.290487-4.290486a5.720649 5.720649 0 0 0-7.150811-7.150811l-4.290487 4.290486a7.150811 7.150811 0 0 0 0 7.150811zM646.433317 153.027356l2.860324-2.860325 4.290487-4.290486c2.860324-1.430162 2.860324-4.290487 0-7.150811h-7.150811l-4.290487 4.290486a5.720649 5.720649 0 0 0 0 7.150811z"
      fill="#FFFFFF" p-id="1153"></path>
    <path
      d="M712.220778 296.043576l78.658921-77.228759 7.150811-10.011135-25.742919-27.173082a12.87146 12.87146 0 0 1-4.290487 14.301622l-65.787461 67.217624-22.882596 22.882595L400.445417 563.483909l-7.150811 7.150811-28.603244 2.860324-97.25103 8.580973c0 10.011135-1.430162 22.882595-1.430162 32.893731l135.86541-12.87146h4.290486a7.150811 7.150811 0 0 0 5.720649-2.860324l300.334063-300.334063z"
      fill="#003570" opacity=".25" p-id="1154"></path>
    <path
      d="M656.444452 137.295572a5.720649 5.720649 0 0 1 0-7.150811 34.323893 34.323893 0 0 1 21.452433-8.580974 20.022271 20.022271 0 0 1 14.301622 10.011136 2.860324 2.860324 0 0 1-1.430162 5.720649c-1.430162 1.430162-4.290487 0-5.720649-1.430163a15.731784 15.731784 0 0 0-8.580973-5.720648 20.022271 20.022271 0 0 0-12.87146 7.150811z"
      fill="#FFFFFF" p-id="1155"></path>
    <path
      d="M308.915036 706.500129l17.161947 2.860325-14.301622 50.055677H357.540551c4.290487-20.022271 8.580973-40.044542 11.441298-62.927137l18.592108 2.860324c-2.860324 21.452433-7.150811 41.474704-11.441297 60.066813h120.133625v18.592108h-125.854274l-8.580973 28.603244h104.401841v15.731785a147.306707 147.306707 0 0 1-41.474704 60.066812 423.328013 423.328013 0 0 0 75.798597 34.323893l-10.011136 17.161947a433.339148 433.339148 0 0 1-81.519245-40.044542 310.345198 310.345198 0 0 1-78.658922 40.044542l-8.580973-17.161947a333.227794 333.227794 0 0 0 71.50811-34.323893 138.725734 138.725734 0 0 1-42.904866-50.055677 268.870494 268.870494 0 0 1-71.50811 97.25103l-10.011136-17.161946a296.043576 296.043576 0 0 0 82.949408-134.435248h-62.927137zM407.596228 872.398945a122.99395 122.99395 0 0 0 40.044542-48.625515h-82.949408a148.736869 148.736869 0 0 0 42.904866 48.625515z m68.647786-131.574923l-12.87146 12.87146a321.786496 321.786496 0 0 0-44.335028-42.904866l11.441298-11.441298a264.580008 264.580008 0 0 1 45.76519 41.474704z m191.641736 25.74292v31.463568h71.50811v85.809733c0 15.731784-7.150811 24.312757-22.882595 24.312757h-24.312758l-4.290487-17.161946h22.882596a10.011135 10.011135 0 0 0 11.441297-11.441298V815.192457h-54.346163v118.703463h-17.161947V815.192457h-55.776326v94.390705h-17.161946v-98.681192l-41.474704 37.184217-12.87146-15.731784a281.741954 281.741954 0 0 0 77.228759-82.949408h-68.647786v-17.161946h78.658921a348.959578 348.959578 0 0 0 14.301622-34.323893H643.572992a224.535466 224.535466 0 0 1-12.87146 32.893731h124.424112v17.161946h-133.005085a351.819902 351.819902 0 0 1-31.463568 48.625515h60.066812v-30.033406z"
      fill="#606266" p-id="1156"></path>
  </svg>
);