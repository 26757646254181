import {observable, action} from "mobx";

class ThreadDataStore {
  @observable subject = "";

  @observable allownoticeauthor = false;

  @observable hiddenreplies = false;

  @observable ordertype = false;

  @observable usesig = false;

  @observable threadtypes = []; // 帖子内容

  @observable select = undefined;

  @observable allowSetPostTime = "";

  @observable postTime = "";

  @observable isDialogVisible = false; // 是否打开主题选择弹窗

  @action
  setPostTime = (postTime) => {
    this.postTime = postTime;
  };

  @action
  setIsDialogVisible = (visible) => {
    this.isDialogVisible = visible;
  };

  @action
  setSubject = (subject) => {
    this.subject = subject;
  };

  @action
  setAllowSetPostTime = (allowSetPostTime) => {
    this.allowSetPostTime = allowSetPostTime;
  };

  @action
  setAllownoticeauthor = (allownoticeauthor) => {
    this.allownoticeauthor = allownoticeauthor === "1";
  };

  @action
  setHiddenreplies = (hiddenreplies) => {
    this.hiddenreplies = hiddenreplies === "1";
  };

  @action
  setOrdertype = (ordertype) => {
    this.ordertype = ordertype === "1";
  };

  @action
  setUsesig = (usesig) => {
    this.usesig = usesig === "1";
  };

  @action
  setThreadtypes = (threadtypes) => {
    this.threadtypes = threadtypes;
  };

  @action
  setSelect = (select) => {
    this.select = select;
  };

  // 初始化时设置所有字段
  @action
  initializeFromResponse = (response) => {
    this.setSubject(response.data.subject);
    this.setAllownoticeauthor(response.data.allownoticeauthor);
    this.setHiddenreplies(response.data.hiddenreplies);
    this.setOrdertype(response.data.ordertype);
    this.setUsesig(response.data.usesig);
    this.setThreadtypes(response.data.threadtypes);
    this.setSelect(response.data.typeid);
    this.setAllowSetPostTime(response.data.allowSetPostTime);
  };
}

// 创建实例导出
const threadDataStore = new ThreadDataStore();
export default threadDataStore;
