import React, {Component} from "react";
import {observer} from "mobx-react";
import {Modal, Input, Select, Checkbox, Button, DatePicker} from "antd";
import threadDataStore from "../../store/pinggu/threadDataStore";
import locale from "antd/es/date-picker/locale/zh_CN";

const {Option} = Select;

@observer
class ThreadDialog extends Component {
  constructor(props) {
    super(props);
    // 在构造函数中初始化状态
    this.state = {
      loading: false, // 添加 loading 状态
    };
  }

  handleSubjectChange = (e) => {
    threadDataStore.setSubject(e.target.value); // 更新帖子标题
  };

  handleConfirm = () => {
    // 点击确定时，调用父组件传递的回调函数
    this.setState({loading: true});
    try {
      const {onConfirm} = this.props;
      if (onConfirm) {
        const data = {
          subject: threadDataStore.subject,
          select: threadDataStore.select,
          usesig: threadDataStore.usesig,
          hiddenreplies: threadDataStore.hiddenreplies,
          ordertype: threadDataStore.ordertype,
          allownoticeauthor: threadDataStore.allownoticeauthor,
          postTime: threadDataStore.postTime,
        };
        onConfirm(data); // 回传数据到父组件
      }

      threadDataStore.setIsDialogVisible(false); // 关闭对话框
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({loading: false});
    }
  };

  render() {
    const {
      subject,
      threadtypes,
      select,
      usesig,
      hiddenreplies,
      ordertype,
      allownoticeauthor,
      allowSetPostTime,
      isDialogVisible,
    } = threadDataStore;
    const {loading} = this.state;
    console.log("是否允许定时发布", allowSetPostTime);
    return (
      <Modal
        title="编辑帖子"
        visible={isDialogVisible}
        onCancel={() => {
          threadDataStore.setIsDialogVisible(false);
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              threadDataStore.setIsDialogVisible(false);
            }}
          >
            取消
          </Button>,
          <Button key="publish" type="primary" loading={loading} onClick={this.handleConfirm}>
            确定
          </Button>,
        ]}
      >
        <Input placeholder="请输入标题" value={subject} onChange={this.handleSubjectChange}/>

        {threadtypes && threadtypes.length > 0 && (
          <Select
            value={select}
            onChange={(value) => threadDataStore.setSelect(value)}
            style={{width: "100%", marginTop: 20}}
            placeholder="请选择主题"
          >
            {threadtypes.map((item) => (
              <Option key={item.typeid} value={item.typeid}>
                {item.name}
              </Option>
            ))}
          </Select>
        )}

        {allowSetPostTime === "1" && (
          <div style={{display: "flex", alignItems: "center", marginTop: 20}}>
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              onChange={(date, dateString) => {
                threadDataStore.setPostTime(dateString);
              }}
              locale={locale} // 设置中文提示
              placeholder="请选择定时发布的时间"
              style={{flex: 1}}
            />
          </div>
        )}

        <div style={{marginTop: 20}}>
          <div>
            <Checkbox checked={usesig} onChange={(e) => threadDataStore.setUsesig(e.target.checked ? "1" : "0")}>
              使用个人签名
            </Checkbox>
          </div>

          <div style={{marginTop: 8}}>
            <Checkbox
              checked={hiddenreplies}
              onChange={(e) => threadDataStore.setHiddenreplies(e.target.checked ? "1" : "0")}
            >
              回帖仅作者可见
            </Checkbox>
          </div>

          <div style={{marginTop: 8}}>
            <Checkbox checked={ordertype} onChange={(e) => threadDataStore.setOrdertype(e.target.checked ? "1" : "0")}>
              回帖倒序排列
            </Checkbox>
          </div>

          <div style={{marginTop: 8}}>
            <Checkbox
              checked={allownoticeauthor}
              onChange={(e) => threadDataStore.setAllownoticeauthor(e.target.checked ? "1" : "0")}
            >
              接收回复通知
            </Checkbox>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ThreadDialog;
